<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="EditLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>retirer </label>
          <input type="text" :class="errors.retirer?'form-control is-invalid':'form-control'"
                 v-model="form.retirer">

          <div class="invalid-feedback" v-if="errors.retirer">
            <template v-for=" error in errors.retirer"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>creat_by </label>
          <input type="text" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                 v-model="form.creat_by">

          <div class="invalid-feedback" v-if="errors.creat_by">
            <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>debut </label>
          <input type="text" :class="errors.debut?'form-control is-invalid':'form-control'"
                 v-model="form.debut">

          <div class="invalid-feedback" v-if="errors.debut">
            <template v-for=" error in errors.debut"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>fin </label>
          <input type="text" :class="errors.fin?'form-control is-invalid':'form-control'"
                 v-model="form.fin">

          <div class="invalid-feedback" v-if="errors.fin">
            <template v-for=" error in errors.fin"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>pointeuses </label>

          <CustomSelect
              :key="form.pointeuse"
              :url="`${axios.defaults.baseURL}/api/pointeuses-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.pointeuse"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.pointeuse_id=data.id;form.pointeuse=data}"
          />
          <div class="invalid-feedback" v-if="errors.pointeuse_id">
            <template v-for=" error in errors.pointeuse_id"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>sites </label>

          <CustomSelect
              :key="form.site"
              :url="`${axios.defaults.baseURL}/api/sites-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.site"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.site_id=data.id;form.site=data}"
          />
          <div class="invalid-feedback" v-if="errors.site_id">
            <template v-for=" error in errors.site_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <div class="d-flex justify-content-between">
        <button type="submit" class="btn btn-primary">
          <i class="fas fa-floppy-disk"></i> Mettre à jour
        </button>
        <button type="button" class="btn btn-danger" @click.prevent="DeleteLine()">
          <i class="fas fa-close"></i> Supprimer
        </button>
      </div>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'EditSitespointeuses',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: ['data', 'gridApi', 'modalFormId',
    'pointeusesData',
    'sitesData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        site_id: "",

        pointeuse_id: "",

        retirer: "",

        creat_by: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",

        debut: "",

        fin: "",
      }
    }
  },

  mounted() {
    this.form = this.data
  },
  methods: {

    EditLine() {
      this.isLoading = true
      this.axios.post('/api/sitespointeuses/' + this.form.id + '/update', this.form).then(response => {
        this.isLoading = false
        this.gridApi.applyServerSideTransaction({
          update: [
            response.data
          ],
        });
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    DeleteLine() {
      this.isLoading = true
      this.axios.post('/api/sitespointeuses/' + this.form.id + '/delete').then(response => {
        this.isLoading = false

        this.gridApi.applyServerSideTransaction({
          remove: [
            this.form
          ]
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        console.log(error.response.data)
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de la suppression')
      })
    },
  }
}
</script>
